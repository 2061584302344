import { ValidatorFn, AbstractControl } from '@angular/forms'

export default class Utils {
  public static PasswordComplexityErrorMessage =
    'Password must include a minimum of 3 lowercase, 3 uppercase, 3 special characters and 3 numbers.'
  public static MinPasswordLength = 12
  public static MaxPasswordLength = 20
  public static MaxPasswordErrorMessage = `Password must be less than ${Utils.MaxPasswordLength} characters long.`
  public static MinPasswordErrorMessage = `Password must be at least ${Utils.MinPasswordLength} characters long.`
  public static SpecialCharacters = [
    ' ',
    '!',
    '"',
    '#',
    '$',
    '%',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    '[',
    '\\',
    ']',
    '^',
    '_',
    '`',
    '{',
    '|',
    '}',
    '~',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '@',
  ]

  static msToTimeFormat(s) {
    var ms = s % 1000
    s = (s - ms) / 1000
    var secs = s % 60
    s = (s - secs) / 60
    var mins = s % 60
    var hrs = (s - mins) / 60

    let format: string = ''

    if (hrs < 10) {
      format += '0' + hrs
    } else {
      format += hrs
    }
    format += ':'

    if (mins < 10) {
      format += '0' + mins
    } else {
      format += mins
    }
    format += ':'

    if (secs < 10) {
      format += '0' + secs
    } else {
      format += secs
    }

    return format
    //return hrs + ':' + mins + ':' + secs + '.' + ms;
  }

  static passwordComplexityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let password = control.value
      if (password == null || password.length < Utils.MinPasswordLength) {
        return { passwordcomplex: {} }
      }

      let passwordLowerChr1Match = password.match(new RegExp('[a-z]', 'g'))
      let passwordLowerChr1Check =
        passwordLowerChr1Match !== null && passwordLowerChr1Match.length >= 3
      let passwordUpperChr2Match = password.match(new RegExp('[A-Z]', 'g'))
      let passwordUpperChr2Check =
        passwordUpperChr2Match !== null && passwordUpperChr2Match.length >= 3
      let passwordNumMatch = password.match(new RegExp('[0-9]', 'g'))
      let passwordNumCheck =
        passwordNumMatch !== null && passwordNumMatch.length >= 3
      const specialCharacterStr = Utils.SpecialCharacters.join('')
      const pattern = new RegExp(
        '[' + specialCharacterStr.replace(/[.*+?^${}()/|[\]\\]/g, '\\$&') + ']',
        'g',
      )
      let passwordSpeChrMatch = password.match(pattern)
      let passwordSpeChrCheck =
        passwordSpeChrMatch !== null && passwordSpeChrMatch.length >= 3
      return passwordLowerChr1Check &&
        passwordUpperChr2Check &&
        passwordNumCheck &&
        passwordSpeChrCheck
        ? null
        : {
            passwordcomplex: {
              passwordLowerChr1Check: passwordLowerChr1Check,
              passwordUpperChr2Check: passwordUpperChr2Check,
              passwordNumCheck: passwordNumCheck,
              passwordSpeChrCheck: passwordSpeChrCheck,
            },
          }
    }
  }

  static generatePasswordString() {
    let lowerChars = `abcdefghijklmnopqrstuvwxyz`
    let upperChars = lowerChars.toUpperCase()
    let number = `1234567890`
    let specialChars = Utils.SpecialCharacters.join('')
    let pass = ``
    let ch = 0
    for (let i = 0; i < 4; i++) {
      ch = Math.floor(Math.random() * lowerChars.length)
      pass += lowerChars.charAt(ch)
      ch = Math.floor(Math.random() * upperChars.length)
      pass += upperChars.charAt(ch)
      ch = Math.floor(Math.random() * number.length)
      pass += number.charAt(ch)
      ch = Math.floor(Math.random() * specialChars.length)
      pass += specialChars.charAt(ch)
    }
    return pass
  }
}
