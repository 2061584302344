import { SessionDecisionType } from "../reference/session-decision-type";

export class Report {
    facultyIds: number[];
    schoolIds: number[];
    unitIds: number[];
  }

  export class ReportFlaggedFramePercentage {
    facultyIds: number[];
    schoolIds: number[];
    unitIds: number[];
    percentageFlaggedThreshold: number;
  }

  export class SessionsReviewedPercentage {
    facultyIds: number[];
    schoolIds: number[];
    unitIds: number[];
    courseIds: number[];
  }

  export class ReportUnProcessedResponse {
    faculty: string;
    school: string;
    unit: string;
    assessment: number;
    student: number;
    unitId: number;
    examinationId: number;
    sessionId: number;
    fullReport: any;
  }

  export class AutoAuthenticationFailureResponse {
    faculty: string;
    school: string;
    unit: string;
    assessment: number;
    student: number;
    unitId: number;
    examinationId: number;
    sessionId: number;
    probability: any;
    fullReport: any;
  }

  export class ConfirmedBreachesResponse {
    faculty: string;
    school: string;
    unit: string;
    assessment: number;
    student: number;
    unitId: number;
    examinationId: number;
    sessionId: number;
    sessionDecisionType: SessionDecisionType;
    fullReport: any;
  }
  
  export class StudentTestMinutesResponse {
    faculty: string;
    school: string;
    unit: string;
    studentName: number;
    studentNumber: number;
    testMinutes: number;
  }

  export class ConfirmedBreachesModel {
    facultyIds: number[];
    schoolIds: number[];
    unitIds: number[];
    userIds: number[];
    yearSemesters: string[];
    studyPeriodIds: number[];
  }

  export class AuditTrailModel {
    facultyIds: number[];
    schoolIds: number[];
    unitIds: number[];
    userIds: number[];
  }

  export class AuditTrailResponse {
    fullName: string;
    event: string;
    eventTimestampDate: string;
    eventTimestamp: string;
  }

  export class UserFilterModel {
    facultyIds: number[];
    schoolIds: number[];
    unitIds: number[];
    roleIds: number[];
  }

  export class UnitFilterModel {
    // facultyIds: number[];
    // schoolIds: number[];
    // unitIds: number[];
    courseName: string;
    unitName: string;
    numberOfAssessments: string
    date: Date;
  }

  export class ReportFlaggedFrameResponse {
    faculty: string;
    school: string;
    unit: string;
    averageFlaggedFrames: string;
  }

  export class SessionReviewedFrameResponse {
    faculty: string;
    school: string;
    course: string;
    unit: string;
    reviewed: number;
    notReviewed: number;
    ratio: string;
  }
