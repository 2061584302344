export enum AssessmentSettings {
  none = 0,
  singleScreenOnly = 1 << 0,
  audioFeedMandatory = 1 << 1,
  videoFeedMandatory = 1 << 2,
  processAudio = 1 << 3,
  processVideo = 1 << 4,
  processDesktop = 1 << 5,
}

export class AssessmentSettingsMapper {
  public static mapAssessmentSettings = (
    singleScreenOnly: boolean,
    audioFeedMandatory: boolean,
    videoFeedMandatory: boolean,
    processAudio: boolean,
    processVideo: boolean,
    processDesktop: boolean,
  ): AssessmentSettings => {
    let assessmentSettings: AssessmentSettings = AssessmentSettings.none

    if (singleScreenOnly) {
      assessmentSettings |= AssessmentSettings.singleScreenOnly
    }
    if (audioFeedMandatory) {
      assessmentSettings |= AssessmentSettings.audioFeedMandatory
    }
    if (videoFeedMandatory) {
      assessmentSettings |= AssessmentSettings.videoFeedMandatory
    }
    if (processAudio) {
      assessmentSettings |= AssessmentSettings.processAudio
    }
    if (processVideo) {
      assessmentSettings |= AssessmentSettings.processVideo
    }
    if (processDesktop) {
      assessmentSettings |= AssessmentSettings.processDesktop
    }

    return assessmentSettings
  }
}
